$module-width: 800px;
$mobile-width: 768px;

// Mark: Common

// Mark: Header

.border-bottom-radius {
	border-radius: 0px 0px 40px 40px;
}

.header-container {
  flex-flow: row wrap;
  background: url('assets/v2/header-bg@2x.png') no-repeat right bottom / 550px;
}

.header-container > .module-text-container {
  width: 100%;
}

.downloadButtonsContainer {
	margin: 140px 0 22px 0;
	filter: $drop-shadow;
}

@media only screen and (max-width: $mobile-width) {
  header {
    padding: 30px;
  }

  .header-container {
    background: url('assets/v2/header-bg@2x.png') no-repeat right bottom / 389px 280px;
  }

  .header-container > .module-text-container > h2 {
    font-size: 3.5rem;
  }
  .header-container > .module-text-container {
    font-size: 1.5rem;
    padding-top: 0;
    padding-bottom: 0;
  }

  .downloadButtonsContainer {
    margin: 40px 0 320px 0;
    text-align: center;
  }
}

header {
  display: flex;
  width: 100%;
}

.rounded-font {
  font-family: "ui-rounded", $font;
}

.logo {
	display: flex;
  font-size: 2em;
	justify-content: flex-start;
	align-items: center;
	height: 100px;
	margin-right: 30px;
}

.logo > p {
	color: white;
	white-space: nowrap;
	display: flex;
	font-weight: bold;
	margin-left: 15px;
}

@media only screen and (max-width: $mobile-width) {

	.logo {
		height: 80px;
		margin-right: 0px;
	}

	.logo > p {
		display: none;
	}
	
} 

.logo {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	height: 115px;
	margin-right: 30px;
}

.logo > p {
	color: $header-title-color;
	white-space: nowrap;
	display: flex;
	font-weight: bold;
	margin-left: 15px;
}

@media only screen and (max-width: 768px) {

	.logo {
		height: 80px;
		margin-right: 0px;
	}

	.logo > p {
		display: none;
	}

}

.headerIcon {
	width: 50px;
	height: 50px;
	-webkit-clip-path: url(#shape);
	clip-path: url(#shape);
}

.appIconShadow {
	display: flex;
	filter: $drop-shadow;
}

.appStore {
	height: 65px;
}


// Mark: Navigation Links

.scroll {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: flex-end;

	height: 115px;
	//margin-left: 15px;

	white-space: nowrap;
	overflow-x: auto;
	overflow-y: hidden;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;
}

@media only screen and (max-width: $mobile-width) {

	.scroll {
		height: 80px;
		justify-content: flex-start;
	}	
	
} 

.scroll::-webkit-scrollbar {
	display: none;
}

nav > ul {
	color: #fff;
	display: flex;
	list-style-type: none;

}

nav > ul li {
	padding-left: 30px;
}

@media only screen and (max-width: $mobile-width) {

	nav > ul li {
		padding-left: 20px;
	}

}

nav > ul li:first-child {
	padding-left: 0px;
	padding-left: 15px;
}

nav > ul li:last-child {
	padding-right: 15px;
}

nav > ul li a:link,
nav > ul li a:visited {
	text-decoration: none;
	color: rgba($header-title-color, 1);
}

nav > ul li a:hover,
nav > ul li a:active {
	text-decoration: none;
	color: rgba($header-title-color, 1);
}

// Mark: feature module

.module-content {
  margin: 0 auto;
  padding-top: 80px;
  max-width: $module-width;
  display: flex;
}

.module-content > img {
  max-width: 95%;
  align-self: center;
}

.module h2 {
  font-size: 4rem;
  font-weight: normal;
  margin: 0.5em 0;
}

.module-content-reverse-large-screen {
  flex-direction: row-reverse;
}

.feature-purple {
  background-color: #DBC2EB;
}

.feature-yellow {
  background-color: #FFF8E8;
}

.feature-green {
  background-color: #DBF7E8;
}

.feature-gray {
  background-color: #f0f0f0;
}

@media only screen and (max-width: 992px) {
	.module-content {
		flex-wrap: wrap;
    padding-top: 11px;
    text-align: center;
    justify-content: center;
	}
  .module-text-container {
    padding: 30px;
  }
 .module-content-reverse-large-screen {
    flex-direction: row;
  }
}

.page {
	margin-top: 30px;
	margin-bottom: 70px;
	grid-column: 3/11;
}

@media only screen and (max-width: $mobile-width) {

	.page {
		margin-top: 30px;
		margin-bottom: 70px;
		grid-column: 1/-1;
	}
	
} 

@media only screen and (max-width: $mobile-width) {

	.divider {
		position: relative;
		min-width: 1px;
		max-width: 1px;
		background-color:rgba(127, 127, 127, 0.2);
		display: inline-block;
		margin-left: 15px;
	}	
	
} 

// Mark: all site container

.container {                                   // Set up the container for the site content
       display: grid;
       margin: auto;
       max-width: $content-width;
       padding-left: 15px;
       padding-right: 15px;
       grid-template-columns: repeat(12, 1fr);
       grid-template-rows: 115px 876px auto auto;
       grid-column-gap: 30px;
       grid-template-areas:
               "h h h h h h h h h h h h"
               "p p p p p i i i i i i i"
               "k k k k k k k k k k k k"
               "c c c c c c c c c c c c"
               "f f f f f f f f f f f f";
}

@media only screen and (max-width: $mobile-width) {

       .container {                                    // Set up the container for the site content
               grid-template-rows: 80px 811px auto auto;
       }
}

// Sub Page Header Styling
.subPageHeaderBackground .logo > p {
	color: #000;
}

.subPageHeaderBackground nav > ul li a:link,
.subPageHeaderBackground nav > ul li a:visited {
	color: rgba(#000, 0.6);
}

.subPageHeaderBackground nav > ul li a:hover,
.subPageHeaderBackground nav > ul li a:active {
	color: rgba(#000, 1);
}

// Concept list

.keynotes {
	grid-area: k;
	display: flex;
	flex: 0 1 auto;
	align-content: flex-start;
	justify-content: flex-start;
	flex-grow: 1;
	flex-wrap: wrap;
	margin-top: 93px;
}

// Feature List

.features {
	grid-area: c;
	display: flex;
	flex: 0 1 auto;
	align-content: flex-start;
	justify-content: flex-start;
	flex-grow: 1;
	flex-wrap: wrap;
	margin-top: 20px;
  text-align: center;
  padding-bottom: 100px;
}

.feature {
	display: flex;
  flex-direction: column;
	padding-top: 63px;
	padding-left: 15px;
	padding-right: 15px;
	width: calc(100%/3);
}

.feature .fa-stack {
  display: block;
  font-size: 5rem;
  margin-bottom: 3rem;
  width: 100%;
}

.feature:nth-child(-n+3) {
    padding-top: 0px;
}

.feature:nth-child(3n) {
	padding-right: 0px;
}

.feature:nth-child(3n+1) {
	padding-left: 0px;
}

.iconBack {
	color: $feature-icons-background-color;
}

.iconTop {
	color: $feature-icons-foreground-color;
}

.socialIconBack {
	color: $social-icons-background-color;
}

.socialIconTop {
	color: $social-icons-foreground-color;
}

.featureText > h3 {
	color: $feature-title-color;
}

.featureText > p {
	color: $feature-text-color;
	margin-top: 8px;
	line-height: 1.5;
}

@media only screen and (max-width: 992px) {

	.features {
		flex-grow: 1;
		flex-direction: row;
		flex-wrap: wrap;
		margin-top: 11px;
	}

	.feature {
		display: flex;
		padding-top: 41px;
		padding-left: 15px;
		padding-right: 15px;
		width: 50%;
	}

	.feature:nth-child(-n+3) {
		padding-top: 41px;   
	}

	.feature:nth-child(3n) {
		padding-right: 15px;
	}
	
	.feature:nth-child(3n+1) {
		padding-left: 15px;
	}
	
}

@media only screen and (max-width: 375px) {

	.features {
		flex-grow: 1;
		flex-direction: row;
		flex-wrap: wrap;
		margin-top: 11px;
	}

	.feature {
		display: flex;
		padding-top: 41px;
		padding-left: 0px;
		padding-right: 0px;
		width: 100%;
	}

	.feature:nth-child(-n+3) {
		padding-top: 41px;   
	}

	.feature:nth-child(1) {
		padding-top: 0px;   
	}

	.feature:nth-child(3n) {
		padding-right: 0px;
	}
	
	.feature:nth-child(3n+1) {
		padding-left: 0px;
	}
	
} 

// Feedback

.feedback-module-content {
  flex-direction: column;
  padding: 30px 0 150px 0;
  max-width: 800px;
}

.feedback-card-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.feedback-header {
  text-align: center;
  margin: 0 auto 40px auto;
  width: 100%;
}

.feedback-card {
  display: flex;
  flex-direction: column;
  height: 300px;
  margin: 1.5rem;
  text-align: left;
  background-color: white;
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
  color: #4a4a4a;
  width: 28%;
  padding: 4rem 3rem 2rem 3rem;
}

.feedback-card h3 {
  padding-top: 2rem;
  font-size: 1.8rem;
}

.feedback-content {
  flex-grow: 1;
  font-size: 1.4rem;
  padding: 2rem 0;
}

.username {
  font-size: 1.2rem;
}

@media only screen and (max-width: $mobile-width) {
  .feedback-card {
    max-width: 100%;
    width: 60%;
    padding: 4rem 3rem 2rem 3rem;
  }
}

// Footer

footer {
	grid-area: f;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.footerText {
	color: $footer-text-color;
	display: block;
	line-height: 1.5;
	width: 100%;
	text-align: center;
	padding: 20px 0;
}

.footerIcons {
	padding-bottom: 70px;
	display: flex;
	flex: 0 0 100%;
	justify-content: center;
}

.footerLinks {
	display: flex;
	text-align: center;
	padding-bottom: 70px;
}

.footerLinks a {
	margin-right: 30px;
}

.footerLinks a:last-child {
	margin-right: 0px;
}

.footer-download-module-content {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0;
}

.footer-download-module-content p {
  padding: 40px 0;
  font-size: 3rem;
}

.footer-download-module-content a {
  padding: 40px 0;
}




@media only screen and (max-width: $mobile-width) {

	.footerLinks {
		flex-direction: column;
	}

	.footerLinks a {
		justify-content: stretch;
		margin-right: 0px;
		margin-top: 20px;
	}

	.footerLinks a:first-child {
		margin-top: 0px;
	}	

}

@media only screen and (max-width: 992px) {
	
	.footerText {
		color: $footer-text-color;
		display: block;
		line-height: 1.5;
		width: 100%;
		text-align: center;
		padding-top: 54px;
		padding-bottom: 61px;
	}
	
	.footerIcons {
		padding-bottom: 70px;
		display: flex;
	}
	
} 

.hidden {
	display: none;
}
